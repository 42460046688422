<template>
  <div class="welcome">
    <section class="hero is-medium is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">{{ JSON.parse($route.params.school).name }}</h1>
          <h1 class="title">Thanks for registering</h1>
          <h2 class="subtitle">
            Check your email for a confirmation link to continue.
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'welcome',
}
</script>
